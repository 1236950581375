import React from 'react'
import styled from 'styled-components'
import { EmailIcon, ContactIcon, UserIcon, AddressIcon } from '@pga/pga-component-library'

const Wrapper = styled.div.attrs({
  className: 'col-lg-6 col-md-6'
})`
  i {
    padding: 0;
  }
`

const UniversityCard = ({ item, i }) => (
  <Wrapper>
    <div className='card card-stretch'>
      <div className='card-body d-flex flex-column'>
        <div className='d-flex align-items-center'>
          <img alt={item.title} className='mr-3' src={item.image} width={110} />
          <h4 className='title-serif'><a href={item.url}>{item.title}</a></h4>
        </div>
        <div className='card-info'>
          <div className='card-line'>
            <UserIcon />
            <ul className='list-unstyled ml-3'>
              <li>{item.director}</li>
            </ul>
          </div>
          <div className='card-line'>
            <EmailIcon />
            <ul className='list-unstyled ml-3'>
              <li>
                <a href={item.email}>{item.email}</a>
              </li>
            </ul>
          </div>
          <div className='card-line'>
            <ContactIcon />
            <ul className='list-unstyled ml-3'>
              <li>{item.phone1}</li>
              <li>{item.phone2}</li>
            </ul>
          </div>
          <div className='card-line'>
            <AddressIcon />
            <ul className='list-unstyled ml-3'>
              <li>{item.address1}</li>
              <li>{item.address2}</li>
              <li>{item.address3}</li>
              <li>{item.address4}</li>
            </ul>
          </div><a className='btn-link btn-block text-uppercase mt-3 pointer' href={`#university-${i}`} data-toggle='modal'>Watch Video</a>
          <div aria-hidden='true' className='modal fade modal__university-video' id={`university-${i}`} role='dialog' tabIndex={-1}>
            <div className='modal-dialog modal-dialog-centered modal-lg' role='document'>
              <div className='modal-content'>
                <div className='modal-header'>
                    &nbsp;
                </div>
                <div className='modal-body'>
                  <div className='embed-responsive embed-responsive-16by9'>
                    <iframe frameBorder={0} src={item.video_link} title={i} />
                  </div>
                </div>
              </div>
            </div>
          </div><a className='btn-link btn-block text-uppercase mt-3' href={item.url} target='_blank' rel='noopener noreferrer'>Read more</a>
        </div>
      </div>
    </div>
  </Wrapper>
)

export default ({ items }) => (
  <div className='container'>
    <div className='row justify-content-center'>
      {items
        ? items.map((item, i) => <UniversityCard key={i} item={item} i={i} />)
        : null}
    </div>
  </div>
)
